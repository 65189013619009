<template>
    <div v-bind:id="'videoContainer-' + this.vidId" class="post-video">
        <video v-bind:id="'video-' + this.vidId" :poster="this.thumbnail" preload="metadata">
            <source :src="this.videoLink" type="video/mp4">
        </video>

        <button v-bind:id="'playLarge-' + this.vidId" class="playLarge" type="button">
            <img src="../../assets/img/icon_play.svg" alt="Play">
        </button>

        <!-- <button id="pauseLarge" type="button">
            <img src="../../assets/img/icon_pause.svg" alt="Play Pause">
        </button> -->

        <div v-bind:id="'video-controls-' + this.vidId" class="controls">
            <button v-bind:id="'playpause-' + this.vidId" type="button" class="playpause">
                <img v-bind:id="'playButton-' + this.vidId" src="../../assets/img/icon_play.svg" alt="Play Pause" class="playButton">
                <img v-bind:id="'pauseButton-' + this.vidId" src="../../assets/img/icon_pause.svg" alt="Pause" class="pauseButton">
            </button>

            <input v-bind:id="'progress-' + this.vidId" type="range" step="0.01" value="0" class="progress">

            <button v-bind:id="'mute-' + this.vidId" type="button" class="mute">
                <img v-bind:id="'muteButton-' + this.vidId" src="../../assets/img/icon_mute.svg" alt="Mute" class="muteButton">
                <img v-bind:id="'unmuteButton-' + this.vidId" src="../../assets/img/icon_unmute.svg" alt="Unmute" class="unmuteButton">
            </button>

            <input v-bind:id="'volume-' + this.vidId" type="range" min="0" max="1" step="0.01" value="0.5" class="volume">

            <button v-bind:id="'fs-' + this.vidId" type="button" class="fs">
                <img src="../../assets/img/icon_fullScreen.svg" alt="Fullscreen">
            </button>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'


export default {
    name: 'VideoPlayer',
    props: {
        vidId: Number,
        videoLink: String,
        thumbnail: String
    },

    mounted() {

        // Video Player

        var supportsVideo = !!document.createElement('video').canPlayType;

        if (supportsVideo) {
            // set up custom controls
            var videoContainer = document.getElementById('videoContainer-' + this.vidId);
            var video = document.getElementById('video-' + this.vidId);
            var videoId = this.vidId

            if ($('#podcastThumbnail').length) {
                var thumb = document.getElementById('podcastThumbnail');
            }

            var videoStarted = false;

            // Hide the default controls and lower volume
            video.controls  = false;
            video.volume    = 0.5;

            var playpause = document.getElementById('playpause-' + videoId);
            var playLarge = document.getElementById('playLarge-' + videoId);
            // var pauseLarge = document.getElementById('pauseLarge');
            var mute = document.getElementById('mute-' + videoId);
            var volume = document.getElementById('volume-' + videoId);
            var progress = document.getElementById('progress-' + videoId);
            var fullscreen = document.getElementById('fs-' + videoId);

            // Basic Controls

            playpause.addEventListener('click', function() {
                if (video.paused || video.ended) {
                    video.play();
                    $('#playButton-' + videoId).fadeOut(300);
                    $('#pauseButton-' + videoId).fadeIn(300);
                    $('#playLarge-' + videoId).fadeOut(300);
                }
                else {
                    video.pause();
                    $('#playButton-' + videoId).fadeIn(300);
                    $('#pauseButton-' + videoId).fadeOut(300);
                    $('#playLarge-' + videoId).fadeIn(300);
                    $('#pauseLarge-' + videoId).fadeOut(300);
                } 
            });

            video.addEventListener('click', function() {
                if (video.paused || video.ended) {
                    video.play();
                    $('#playButton-' + videoId).fadeOut(300);
                    $('#playLarge-' + videoId).fadeOut(300);
                    $('#pauseButton-' + videoId).fadeIn(300);

                    videoStarted = true;
                }
                else {
                    video.pause();
                    $('#playButton-' + videoId).fadeIn(300);
                    $('#pauseButton-' + videoId).fadeOut(300);
                    $('#playLarge-' + videoId).fadeIn(300);
                    // $('#pauseLarge').fadeOut(300);
                } 
            });

            if ($('#podcastThumbnail-' + videoId).length) {
                thumb.addEventListener('click', function() {
                    if (video.paused || video.ended) {
                        video.play();
                        $('#playButton-' + videoId).fadeOut(300);
                        $('#playLarge-' + videoId).fadeOut(300);
                        $('#pauseButton-' + videoId).fadeIn(300);

                        videoStarted = true;
                    }
                    else {
                        video.pause();
                        $('#playButton-' + videoId).fadeIn(300);
                        $('#pauseButton-' + videoId).fadeOut(300);
                        $('#playLarge-' + videoId).fadeIn(300);
                    } 
                });
            }



            mute.addEventListener('click', function() {
                if (video.muted) {
                    video.muted = false;
                    $('#unmuteButton-' + videoId).fadeOut(300);
                    $('#muteButton-' + videoId).fadeIn(300);
                } else {
                    video.muted = true;
                    $('#unmuteButton-' + videoId).fadeIn(300);
                    $('#muteButton-' + videoId).fadeOut(300);
                }
            });

            playLarge.addEventListener('click', function(e) {
                e.preventDefault();

                video.play();
                $('#playLarge-' + videoId).fadeOut(300);
                $('#playButton-' + videoId).fadeOut(300);
                $('#pauseButton-' + videoId).fadeIn(300);

                videoStarted = true;
            });

            // pauseLarge.addEventListener('click', function() {
            //     video.pause();
            //     $('#pauseLarge').fadeOut(300);

            //     $('#pauseButton').fadeOut(300);
            //     $('#playButton').fadeIn(300);

            //     videoStarted = true;
            // });


            // Controls Fade

            videoContainer.addEventListener('mousemove', function() {
                (videoStarted) ? $('#video-controls-' + videoId).css('opacity', 1) : false;
                
                // if (!video.paused) {
                //     $('#pauseLarge').fadeIn(300);
                // }
            });

            videoContainer.addEventListener('mouseleave', function() {
                $('#video-controls-' + videoId).css('opacity', 0);
                // $('#pauseLarge').fadeOut(300);
            });

            // Volume

            volume.oninput = function() {
                video.volume = volume.value;
            };

            // Progress Bar

            video.addEventListener('loadedmetadata', function() {
                progress.setAttribute('max', video.duration);
            });

            video.addEventListener('timeupdate', function() {
                progress.value = video.currentTime;
            });

            progress.oninput = function() {
                video.currentTime = progress.value;
            };

            // Fullscreen Support

            var fullScreenEnabled = !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen);

            if (!fullScreenEnabled) {
                fullscreen.style.display = 'none';
            }

            fullscreen.addEventListener('click', function() {
                handleFullscreen();
            });

            var handleFullscreen = function() {
                if (isFullScreen()) {
                    if (document.exitFullscreen) document.exitFullscreen();
                    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
                    else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
                    else if (document.msExitFullscreen) document.msExitFullscreen();
                    setFullscreenData(false);
                }
                else {
                    if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
                    else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
                    else if (videoContainer.webkitRequestFullScreen) videoContainer.webkitRequestFullScreen();
                    else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
                    setFullscreenData(true);
                }
            }

            var isFullScreen = function() {
                return !!(document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
            }

            var setFullscreenData = function(state) {
                videoContainer.setAttribute('data-fullscreen', !!state);
            }

            document.addEventListener('fullscreenchange', function() {
                setFullscreenData(!!(document.fullscreen || document.fullscreenElement));
            });
            document.addEventListener('webkitfullscreenchange', function() {
                setFullscreenData(!!document.webkitIsFullScreen);
            });
            document.addEventListener('mozfullscreenchange', function() {
                setFullscreenData(!!document.mozFullScreen);
            });
            document.addEventListener('msfullscreenchange', function() {
                setFullscreenData(!!document.msFullscreenElement);
            });
        }
    }
}


</script>